
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/centre/[orgName]/[orgID]",
      function () {
        return require("private-next-pages/centre/[orgName]/[orgID]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/centre/[orgName]/[orgID]"])
      });
    }
  