import { SVGProps } from "./types";

export const MapUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.66667 16.2222L3.42995 14.1039C3.16645 13.9721 3 13.7028 3 13.4082V5.03624C3 4.45806 3.60846 4.082 4.12561 4.34058L7.66667 6.1111M7.66667 16.2222L12.3333 13.8889M7.66667 16.2222V6.1111M12.3333 13.8889L15.8744 15.6594C16.3915 15.918 17 15.5419 17 14.9637V6.5918C17 6.2972 16.8336 6.02788 16.5701 5.89613L12.3333 3.77777M12.3333 13.8889V3.77777M12.3333 3.77777L7.66667 6.1111"
            stroke="currentColor"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export const MapFilled = (props: SVGProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11.66 3.25 8.34 6.58v10.67l3.32-3.33V3.25ZM4.76 4.67a.83.83 0 0 0-1.42.59v8.32c0 .22.1.43.25.59l3.08 3.08V6.58l-1.9-1.91ZM16.41 6.33l-3.08-3.08v10.67l1.9 1.91a.83.83 0 0 0 1.42-.59V6.92a.83.83 0 0 0-.24-.59Z"
            clipRule="evenodd"
        />
    </svg>
);
export const GoogleMapsFilled = (props: SVGProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M15.23 5.25c.84 1.8.65 3.94-.28 5.67-.78 1.41-1.9 2.6-2.87 3.91-.41.59-.83 1.21-1.14 1.89a22.96 22.96 0 0 0-.5 1.4c-.07.2-.16.46-.44.46-.32 0-.42-.36-.48-.61a10 10 0 0 0-1.95-3.61l7.66-9.11ZM7.6 7.27l-2.75 3.26c.5 1.08 1.27 2 2 2.91.17.2.34.43.5.65l3.48-4.12-.03.01a2.45 2.45 0 0 1-3.2-2.7v-.01ZM5.48 4.1a6.18 6.18 0 0 0-.78 6.1l3.33-3.95-.05-.04-2.5-2.11Zm6.37-1.88L9.17 5.39h.03c1.12-.4 2.37.1 2.93 1.1.13.24.23.5.26.74.05.32.07.54.01.85v.01l2.67-3.17a5.82 5.82 0 0 0-3.21-2.7Zm-3.6 3.77 3.25-3.87-.03-.01A5.79 5.79 0 0 0 5.7 3.84h-.02L8.24 6Z"
        />
    </svg>
);
export const WazeUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M11.0622 2.75c-3.125 0-6.3126 2.0625-6.9376 5.1875-.375 1.875.125 3.625-1.4375 3.625-.6875 0-1.125.8125-.6875 1.375.875 1.1875 2.0625 1.875 3.25 2.3125l.385.1538a1.5626 1.5626 0 0 0 1.5526 1.7212 1.5624 1.5624 0 0 0 1.528-1.25h2.5676a1.5622 1.5622 0 0 0 1.5294 1.25 1.5628 1.5628 0 0 0 1.5443-1.3272 1.5615 1.5615 0 0 0-.0625-.7297c2.6188-1.3312 4.0738-3.9818 3.5807-6.8175-.375-3.1881-3.5-5.5006-6.8125-5.5006Zm0 1.25c2.8125 0 5.3125 1.9375 5.75 4.5625.375 2.25-.8125 4.4375-3 5.5l-.2838.1131A1.5636 1.5636 0 0 0 12.8122 14a1.5634 1.5634 0 0 0-1.2488.625H8.6872c-.0763 0-.175-.01-.2626-.0131A1.5625 1.5625 0 0 0 7.1871 14a1.562 1.562 0 0 0-.8543.2563l-.0206-.0063-.5625-.1875c-.875-.3125-1.625-.75-2.25-1.375C4.9996 12.25 5.1246 10.5 5.1871 9.5c.0625-.4375.0625-.875.125-1.3125C5.8746 5.5625 8.6246 4 11.0621 4Zm-2 3.125a.9375.9375 0 1 0 0 1.875.9375.9375 0 0 0 0-1.875Zm3.75 0a.9377.9377 0 0 0-.9375.9375.9375.9375 0 1 0 .9375-.9375ZM8.5028 9.6119a.2725.2725 0 0 0-.1281.0131c-.1875.0625-.3125.1875-.25.375.3125 1.4375 1.6875 2.125 2.8125 2.125s2.5-.6875 2.8125-2.125c0-.1875-.0625-.3125-.25-.375-.1875 0-.3125.0625-.375.25-.25 1.125-1.3125 1.625-2.1875 1.625S8.9997 11 8.7497 9.875c-.047-.1406-.1294-.2456-.247-.2631Z"
        />
    </svg>
);
