import { SVGProps } from "./types";

export const DocumentAddUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.66665 10.7778H12.3333M9.99999 8.44444L9.99999 13.1111M13.8889 17H6.1111C5.25199 17 4.55554 16.3036 4.55554 15.4444V4.55556C4.55554 3.69645 5.25199 3 6.1111 3H10.4556C10.6619 3 10.8597 3.08194 11.0056 3.22781L15.2166 7.43886C15.3625 7.58472 15.4444 7.78255 15.4444 7.98883V15.4444C15.4444 16.3036 14.748 17 13.8889 17Z"
            stroke="currentColor"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const DocumentDuplicateUnfilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.88894 6.11111V12.3333C6.88894 13.1924 7.58539 13.8889 8.4445 13.8889H13.1112M6.88894 6.11111V4.55556C6.88894 3.69645 7.58539 3 8.4445 3H12.0112C12.2175 3 12.4153 3.08194 12.5612 3.22781L15.9945 6.66108C16.1403 6.80694 16.2223 7.00478 16.2223 7.21106V12.3333C16.2223 13.1924 15.5258 13.8889 14.6667 13.8889H13.1112M6.88894 6.11111H5.77783C4.67326 6.11111 3.77783 7.00654 3.77783 8.11111V15.4444C3.77783 16.3036 4.47428 17 5.33339 17H11.1112C12.2157 17 13.1112 16.1046 13.1112 15V13.8889"
            stroke="currentColor"
            strokeWidth={1.67}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const DocumentTextFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.75 4.75C4.75 3.7835 5.5335 3 6.5 3H10.5126C10.9767 3 11.4218 3.18437 11.75 3.51256L14.7374 6.5C15.0656 6.82819 15.25 7.27331 15.25 7.73744V15.25C15.25 16.2165 14.4665 17 13.5 17H6.5C5.5335 17 4.75 16.2165 4.75 15.25V4.75ZM6.5 10C6.5 9.51675 6.89175 9.125 7.375 9.125H12.625C13.1082 9.125 13.5 9.51675 13.5 10C13.5 10.4832 13.1082 10.875 12.625 10.875H7.375C6.89175 10.875 6.5 10.4832 6.5 10ZM7.375 12.625C6.89175 12.625 6.5 13.0168 6.5 13.5C6.5 13.9832 6.89175 14.375 7.375 14.375H12.625C13.1082 14.375 13.5 13.9832 13.5 13.5C13.5 13.0168 13.1082 12.625 12.625 12.625H7.375Z"
            fill="currentColor"
        />
    </svg>
);
